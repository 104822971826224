<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Lead Details"
    description="Lead details information can be edited or updated at any time."
    primary-button-text="Save"
    secondary-button-text="Cancel"
    :disabled="loading"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <div v-if="lead.step?.name === 'Failed'" class="bg-secondary-05 p-4 text-sm text-additional-3-100">
      <div class="flex w-max items-center rounded-lg bg-additional-3-10 px-3 py-1.5 font-semibold">
        <UiIcon name="alert-circle" class="mr-1 size-4"></UiIcon>
        Failure
      </div>
      <p class="mt-3">
        Lead with failed phone number was created. The original number is
        <span class="font-semibold">{{ duplicateWrongPhone }}</span
        >.
      </p>
    </div>
    <form class="h-full" @submit.prevent>
      <h5 class="text-subhead-1 mb-4 text-black-60">Lead contacts</h5>
      <UiInputPhone
        v-model="localLead"
        name="Phone"
        label="Phone number *"
        placeholder="Add a number"
        class="mb-8"
        :error="useGetFieldErrors(v$, ['phone_country_id', 'phone'])"
      />

      <h5 class="text-subhead-1 mb-4 text-black-60">Lead details</h5>
      <UiInputTextField
        v-model="localLead.name"
        label="Lead name *"
        name="Name"
        placeholder="Lead name"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['name'])"
      />
      <UiInputTextField
        v-model="localLead.email"
        label="Email"
        name="email"
        placeholder="Email"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['email'])"
      />
      <UiInputSelect
        v-model="localLead.country_id"
        :items="countries"
        label="Country *"
        name="country"
        placeholder="Choose country"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['country_id'])"
      />
      <UiInputSelect
        v-model="localLead.language_id"
        :items="languages"
        label="Language *"
        name="language"
        placeholder="Language"
        class="mb-4"
        :error="useGetFieldErrors(v$, ['language_id'])"
      />

      <UiInputSelect
        v-model="localLead.timezone_id"
        :items="timezones"
        align-right
        label="Timezone"
        name="Timezone"
        placeholder="Select a timezone"
        class="mb-4"
      />

      <UiInputSelect
        v-model="localLead.lead_source_id"
        :items="sources"
        label="Source *"
        name="Source"
        placeholder="Type or select from the list"
        class="mb-4"
        add-new
        :error="useGetFieldErrors(v$, ['lead_source_id'])"
        @update:model-value="addCustomValue($event)"
        @updated:shallow-value="getSources($event)"
      />
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import omitBy from 'lodash/omitBy'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import type { InputItem, Lead } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const loading = ref(false)
const countries = ref<InputItem[]>([])
const languages = ref<InputItem[]>([])
const sources = ref<InputItem[]>([])
const timezones = ref<InputItem[]>([])

onNuxtReady(async () => await Promise.all([getCountries(), getLanguages(), getSources(), getTimezones()]))

const getCountries = async () => {
  countries.value = await useGetCountriesItems()
}
const getLanguages = async () => {
  languages.value = await useGetLanguageItems()
}

const getSources = async (query?: string) => {
  sources.value = await useGetLeadSourcesItems(query)
  const leadSource = sources.value.find((s) => s.value === props.lead.lead_source_id)
  if (!leadSource && !query) {
    sources.value.unshift({ text: props.lead.source?.name, value: props.lead.lead_source_id })
  }
}

const addCustomValue = async (value: string) => {
  if (!sources.value.length) {
    const { data } = await useAddLeadSource(value)
    sources.value.unshift({ text: data.name, value: data.id })
    localLead.value.lead_source_id = data.id
  }
}

const getTimezones = async () => {
  const data = await useTimezones()
  timezones.value = data.map((t) => ({ text: `${t.offset} ${t.name}`, value: t.id, ...t }))
}

const localLead = ref<Partial<Lead>>({
  ...props.lead,
  phone_country_id: props.lead.phone_country?.id,
  country_id: props.lead.country?.id,
  language_id: props.lead.language?.id,
  timezone_id: props.lead.timezone?.id,
  lead_source_id: props.lead.source?.id,
  phone: !useGetShowPhoneLead(props.lead) || useGetPhoneHide(props.lead) ? '' : props.lead.phone,
})

let duplicateWrongPhone: string | undefined | number | null
if (localLead.value.step?.name === 'Failed') {
  duplicateWrongPhone = localLead.value.phone
}

const validatePhone = () => {
  return useValidatePhone(+localLead.value.phone!, localLead.value.phone_country_id)
}

const rules = computed(() => ({
  name: { required: helpers.withMessage('The name is required', required) },
  email: { email: helpers.withMessage('The email is not valid', email) },
  phone: {
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
  phone_country_id: {
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
  country_id: { required: helpers.withMessage('Country is required', required) },
  language_id: { required: helpers.withMessage('Language is required', required) },
  lead_source_id: { required: helpers.withMessage('Source is required', required) },
}))

const v$ = useVuelidate(rules, localLead)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
    loading.value = true
    const result = omitBy(localLead.value, (v) => v === null || v === undefined || v === 'hidden')
    try {
      const leadUpdated = await useUpdateLead(props.lead.id, result)
      uiStore.showSnackBanner('Lead updated successfully')
      emits('input', leadUpdated)
      emits('update:modelValue', false)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      loading.value = false
    }
  }
}
</script>

<style scoped></style>
